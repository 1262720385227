import axios from 'axios';
import Cookies from 'js-cookie';
import moment from 'moment';
import { Toast } from 'vant';

// create an axios instance
const service = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? 'http://62.234.50.103:8080' : 'https://s.ououx.com',
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 30000 // request timeout
})

service.interceptors.request.use(
  config => {    
    config.headers['Authorization'] = sessionStorage.getItem("token");
    if(config.params) {            
      config.params._t = moment().format('x');
    }  
    else {
      config.params = {        
        _t : moment().format('x')
      };
    }

    return config;
  },
  error => {    
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const res = response.data
    console.log(response, '===');
    // console.log(window.location.href, '-----')    
    if (res.code != 0) {
      if(res.code == 20019) {        
        function delCodeandstate(to) { //函数作用：去除url中的code和state
          let path = ""
          for (let i in to.query) {
              if (i != "code" && i != "state") {
                  path = path + "&" + i + "=" + to.query[i]
              }
          }
          path = path == "" ? "" : path.substring(1, path.length)
          path = path == "" ? "" : "/?" + path
          return path;
        }
        
        return Promise.reject('error');
      } else {
        // res.msg ? Toast(res.msg) : '';
        return Promise.reject(res);
      }      
    } else {
      return res.data;
    }
  },
  error => {
    console.log('err' + JSON.stringify(error))
    if(error.response && error.response.status == 401) {

    } else {
      let msg = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : error.message;
      console.error(msg);
    }   
    return Promise.reject(error)
  }
)

export default service
