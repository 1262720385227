import wx from 'weixin-js-sdk';
import { Toast } from 'vant';

// 微信公众号分享扫一扫
export const wxShare = {
  install: (Vue) => {
    Vue.prototype.$wxShare = (msg1, msg, cb) => {
      // msg为分享参数，msg1为wxJsApi返回的参数
      // console.log(msg1, msg , '=== wx 1 ====');
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，
        // 参数信息会通过log打出，仅在pc端时才会打印。
        appId: msg1.appId || 'wxaa0a17cc6abc08a3', // 必填，公众号的唯一标识
        timestamp: msg1.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg1.nonceStr, // 必填，生成签名的随机串
        signature: msg1.signature, // 必填，签名，见附录1
        jsApiList: [
          'updateAppMessageShareData',
          'onMenuShareAppMessage',
          'onMenuShareTimeline',
          'updateTimelineShareData'
        ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
      wx.ready(function () {
        let link = window.location.href;
        //分享到朋友圈
        wx.updateAppMessageShareData({
          title: msg.title,   // 分享时的标题
          link: msg.link || link,
          desc: msg.desc,
          imgUrl: msg.imgUrl,    // 分享时的图标
          success: function () {
            console.log("分享成功");
            cb && cb('success');
          },
          fail: function(err) {
            console.log("分享失败", err);
            // iOS 降级处理
            wx.onMenuShareAppMessage({
              title: msg.title,
              desc: msg.desc,
              link: msg.link || link,
              imgUrl: msg.imgUrl,
              success: function () {
                console.log("分享成功(旧版)");
                cb && cb('success');
              },
              cancel: function () {
                console.log("取消分享");
              }
            });
          },
          cancel: function () {
            console.log("取消分享");
          }
        });  
        // 新版分享到朋友圈
        wx.updateTimelineShareData({
          title: msg.title,
          link: msg.link || link,
          imgUrl: msg.imgUrl,
          success: function () {
            console.log("分享到朋友圈成功");
          },
          fail: function(err) {
            console.log("分享到朋友圈失败", err);
            // iOS 降级处理
            wx.onMenuShareTimeline({
              title: msg.title,
              link: msg.link || link,
              imgUrl: msg.imgUrl,
              success: function () {
                console.log("分享到朋友圈成功(旧版)");
              }
            });
          }
        });
      });
      wx.error(function (res) {
        cb('false');
        console.log(res, '--- wxerror --');
      });
    }

    Vue.prototype.$scanQRCode = (msg1, msg, cb) => {
      // 初始化微信JS-SDK
      wx.config({
        debug: false, // 是否开启debug模式（非正式发布时建议打开）
        appId: msg1.appId || 'wxaa0a17cc6abc08a3', // 必填，公众号的唯一标识
        timestamp: msg1.timestamp, // 必填，生成签名的时间戳
        nonceStr: msg1.nonceStr, // 必填，生成签名的随机串
        signature: msg1.signature, // 必填，签名，见附录1
        jsApiList: ['scanQRCode'] // 需要使用的API列表
      });

      // 当微信JS-SDK初始化完成后会触发ready事件
      wx.ready(() => {
        console.log('微信JS-SDK初始化成功');

        // 注册扫一扫按钮点击事件处理程序
        document.getElementById('scanBtn').addEventListener('click', () => {
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫码结果由微信客户端返回，若设置为1则直接返回扫码结果
            scanType: ["qrCode"], // 只支持二维码类型的扫码
            success(res) {              
              const result = res.resultStr; // 扫码结果
              console.log("扫码结果：", result, res);
              cb(result)              
            },
            fail(err) {
              console.error("扫码失败：", err);
            }
          })
        });
      });

      // 如果微信JS-SDK初始化失败，则输出错误信息
      wx.error((err) => {
        console.error('微信JS-SDK初始化失败：', err);
      });
    }
  }
}